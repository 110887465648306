<template>
    <PlainLayout :showButton="showButton">
          <template #header>
            <img src="@/assets/layout/images/icon-error.png"/> 
          </template>
          <template #content>
              <h1 class="font-bold">OOOPS</h1>
              <h2 class="font-semibold">{{message}}</h2>
              
              <router-link class="font-bold" :class="'p-button' " v-bind:to="{ name: 'Login' }" v-if="showButton"> Ir a inicio</router-link>
          </template>
    </PlainLayout>
</template>

<script>
import PlainLayout from '@/layout/PlainLayout.vue';
export default {
    props:{
        message: {
            type: String,
            default: "Algo salió mal"
        },
        showButton:{
            type:Boolean,
            default: true
        }
    },
    components: { PlainLayout },
}
</script>