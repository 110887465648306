<template>
    <div class="plain-panel" >
        <div class="plain-panel-header">
            <slot name="header"></slot>
        </div>
        <div class="plain-panel-content">
            <slot name="content"></slot>
        </div>
        <div class="flex justify-content-end p-2" v-if="$store.state.uIsLoggedIn && showButton">
            <a href="#" class="text-primary" @click="salir_onClick()">
               <span class="pi pi-sign-out text-primary"> Salir</span>
            </a>
        </div>
    </div>
</template>

<script>
export default {
    props:{
        showButton:{
          type:Boolean,
          default: true
        }
     },
    data(){
        return {
          
      }
    },
    methods :{
      salir_onClick(){
          this.$store.dispatch('logout');
					this.$router.push("/")
      }
    }
}
</script>


<style lang="scss">

 .plain-panel {
  width: 420px;
  text-align: center;
  background-color: #fafafa;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -282px;
  margin-top: -232px;
  padding: 0;
  border-radius: 3px;
  box-shadow: 0 7px 8px -4px rgba(0, 0, 0, 0.2),
    0 5px 22px 4px rgba(0, 0, 0, 0.12), 0 12px 17px 2px rgba(0, 0, 0, 0.14);
}
.plain-panel .plain-panel-header {
  text-align: center;
  vertical-align: middle;
  width: 108px;
  height: 108px;
  position: relative;
  top: -54px;
  left: 50%;
  margin-left: -54px;
  background: #fafafa;
  border: 10px solid #fafafa;
  border-radius: 50%;
}
.plain-panel .plain-panel-header img {
  width: 88px;
  height: 88px;
}
.plain-panel .plain-panel-content {
  padding: 0px 50px;
  position: relative;
  top: -30px;
}
.plain-panel .plain-panel-content h1 {
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 1.3px;
  color: #616161;
  margin-top: 0;
  margin-bottom: 6px;
  text-align: center;
}
.plain-panel .plain-panel-content h2 {
  font-size: 16px;
  font-weight: normal;
  letter-spacing: 0.5px;
  color: #212121;
  text-align: center;
}
.plain-panel .plain-panel-content button.p-link {
  color: #457fca;
  font-family: Roboto, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial,
    Lucida Grande, sans-serif;
  font-size: 14px;
}

@media screen   {

  @media (max-width: 1024px){
    .plain-panel {
      left: 0;
      margin-left: 0;
      margin-top: -200px;
      width: 100%;
    }
    .plain-panel .plain-panel-content {
      padding: 12px 38px;
    }
  }
  
}




</style>